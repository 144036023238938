import React from 'react';
import {Show, SimpleShowLayout, TextField} from 'react-admin';
import useStyles from "./styles";

const FoodDetails = (props) => {
    const classes = useStyles();
    return (
        <Show {...props}>
            <SimpleShowLayout className={classes.card}>
                <TextField source="foodFact.calorie" label="Calorie Amount"/>
                <TextField source="foodFact.protein" label="Protein Amount"/>
                <TextField source="foodFact.fat" label="Fat Amount"/>
                <TextField
                    source="foodFact.carbohydrate"
                    label="Carbohydrate Amount"
                />
                <TextField source="foodFact.sugar" label="Sugar Amount"/>
                <TextField source="foodFact.fiber" label="Fiber Amount"/>
                <TextField source="foodFact.sodium" label="Sodium Amount"/>
                <TextField source="foodFact.potassium" label="Potassium Amount"/>
                <TextField source="foodFact.calcium" label="Calcium Amount"/>
                <TextField source="foodFact.magnesium" label="Magnesium Amount"/>
                <TextField source="foodFact.iron" label="Iron Amount"/>
                <TextField
                    source="foodFact.cholesterol"
                    label="Cholesterol Amount"
                />
                <TextField source="foodFact.phosphor" label="Phosphor Amount"/>
                <TextField
                    source="foodFact.saturatedFat"
                    label="Saturated FatAmount"
                />
                <TextField
                    source="foodFact.polyunsaturatedFat"
                    label="Polyunsaturated Fat Amount"
                />
                <TextField
                    source="foodFact.monounsaturatedFat"
                    label="Monounsaturated Fat Amount"
                />
                <TextField source="foodFact.transFat" label="Trans Fat Amount"/>
            </SimpleShowLayout>
        </Show>
    );
};

export default FoodDetails;