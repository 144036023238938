import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  minValue,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  TextInput,
  SimpleFormIterator,
  FormDataConsumer,
  SimpleForm,
} from "react-admin";
import resource from "../../config/resource";
import Divider from "../../components/Divider";
import OverviewTable from "./OverviewTable";
import useResource from "../../components/resource/useResource";
import set from "lodash/set";
import { useEffect, useState } from "react";
import useStyles from "./styles";
const PackageForm = (props) => {
  // const classes = useStyles();

  // const [addFarsi, setAddFarsi] = useState(false);

  // useEffect(() => {
  //   if (props?.record?.translations?.fa) setAddFarsi(true);
  // }, [props]);
  const getFoods = useResource(resource.food);
  return (
    <SimpleForm {...props} redirect="list" validate={validation}>
      <BooleanInput
        source="recommended"
        label="is Recommended"
        defaultValue={true}
      />
      <BooleanInput source="deleted" label="is Deleted" defaultValue={false} />
      <ReferenceArrayInput
        source="types"
        reference={resource.dietType}
        label="Types"
        validate={[required()]}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <Divider />
      <SelectInput
        source="meal"
        choices={[
          { id: "breakfast", name: "صبحانه" },
          { id: "lunch", name: "نهار" },
          { id: "dinner", name: "شام" },
          { id: "snack1", name: "میان‌وعده اول" },
          { id: "snack2", name: "میان‌وعده دوم" },
          { id: "snack3", name: "میان‌وعده سوم" },
          { id: "snack4", name: "میان‌وعده چهارم" },
        ]}
        validate={[required()]}
      />
      <ReferenceInput source="category" reference={resource.mealCategory}>
        <SelectInput source="name" />
      </ReferenceInput>
      <Divider />
      <ArrayInput source="foods" validate={[required()]}>
        <SimpleFormIterator>
          <ReferenceInput
            perPage={100}
            source="foodId"
            reference={resource.food}
            label="Food Name"
            validate={[required()]}
            filter={{ hasBarcode: false }}
            sort={{ field: "verified", order: "DESC" }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <Divider />
      <FormDataConsumer>
        {({ formData }) => (
          <ArrayInput source="calorieSizes" validate={[required()]}>
            <SimpleFormIterator>
              <ReferenceInput
                source="calorie"
                reference={resource.packageCalorie}
                label="calorie amount"
                validate={[required()]}
              >
                <SelectInput optionText="amount" source="amount" />
              </ReferenceInput>
              {formData.foods?.map((food, index) => (
                <NumberInput
                  defaultValue={0}
                  key={index}
                  optionText="amount"
                  source={`sizes[${index}].amount`}
                  label={
                    getFoods(
                      formData.foods.map((food) => food?.foodId)
                    )?.filter((foodObj) => foodObj.id === food?.foodId)[0]
                      ?.name || ""
                  }
                  validate={[required(), minValue(0)]}
                />
              ))}
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </FormDataConsumer>
      {/* <p className={classes.language}>add language</p>
      <div className={classes.boxbox}>
        <p className={classes.titleBoxBox}>en</p>
        <TextInput
          label="defaultLanguage"
          source={"translations.defaultLanguage"}
          validate={[required()]}
          // defaultValue="en"
          disabled
        />
        <TextInput
          label="name"
          source={"translations.en.name"}
          validate={[required()]}
        />
        <TextInput
          label="title"
          source={"translations.en.title"}
          validate={[required()]}
        />
        <TextInput
          label="description"
          source={"translations.en.description"}
          validate={[required()]}
        />
      </div>
      {!addFarsi && (
        <button className={classes.btnFarsi} onClick={() => setAddFarsi(true)}>
          Add Farsi
        </button>
      )}

      {addFarsi && (
        <div className={classes.boxbox2}>
          <p className={classes.titleBoxBox}>fa</p>
          <TextInput
            label="نام"
            source={"translations.fa.name"}
            validate={[required()]}
          />
          <TextInput
            label="عنوان"
            source={"translations.fa.title"}
            validate={[required()]}
          />
          <TextInput
            label="توضیحات"
            source={"translations.fa.description"}
            validate={[required()]}
          />
        </div>
      )} */}
      <OverviewTable />
    </SimpleForm>
  );
};

const validation = (values) => {
  const errors = {};
  // check for duplicate foods
  for (let foodIndex in values.foods) {
    if (
      values.foods.filter(
        (food2) => food2?.foodId === values.foods[foodIndex]?.foodId
      ).length > 1
    ) {
      set(errors, `foods[${foodIndex}].foodId`, "تکراری");
    }
  }
  //check for duplicate packageCalories
  for (let calorieSizeIndex in values.calorieSizes) {
    if (
      values.calorieSizes.filter(
        (calorieSize) =>
          calorieSize?.calorie ===
          values.calorieSizes[calorieSizeIndex]?.calorie
      ).length > 1
    ) {
      set(errors, `calorieSizes[${calorieSizeIndex}].calorie`, "تکراری");
    }
  }
  return errors;
};
export default PackageForm;
