import React from "react";
import {
  TextInput,
  SimpleForm,
  BooleanInput,
  required,
  SelectInput,
} from "react-admin";
import { useEffect, useState } from "react";
import useStyles from "./styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const CategoryForm = (props) => {
  const classes = useStyles();

  const [addFarsi, setAddFarsi] = useState(false);

  useEffect(() => {
    if (props?.record?.translations?.fa) setAddFarsi(true);
  }, [props]);
  return (
    <SimpleForm {...props} redirect={"list"}>
      <BooleanInput source="deleted" label="deleted" />
      <TextInput source={"name"} />
      <p className={classes.language}>add language</p>
      <div className={classes.boxbox}>
        <p className={classes.titleBoxBox}>en</p>
        <SelectInput
          label="defaultLanguage"
          source={"translations.defaultLanguage"}
          // validate={[required()]}
          // // defaultValue="en"
          choices={[
            { id: "en", name: "en" },
            { id: "fa", name: "fa" },
          ]}
        />
        <TextInput
          label="name"
          source={"translations.en.name"}
          // validate={[required()]}
        />
      </div>
      {!addFarsi && (
        <button className={classes.btnFarsi} onClick={() => setAddFarsi(true)}>
          Add Farsi
        </button>
      )}

      {addFarsi && (
        <div className={classes.boxbox2}>
          <div className={classes.boxTitle}>
            <p className={classes.titleBoxBox}>fa</p>
            <HighlightOffIcon
              style={{ fill: "#FF0000", cursor: "pointer" }}
              onClick={() => setAddFarsi(false)}
            />
          </div>
          <TextInput
            label="نام"
            source={"translations.fa.name"}
            // validate={[required()]}
          />
        </div>
      )}
    </SimpleForm>
  );
};

export default CategoryForm;
