import React from "react";
import {
  SelectInput,
  SimpleForm,
  TextInput,
  Edit,
  BooleanInput,
  required,
  NumberInput,
} from "react-admin";
import Upload from "../../components/inputs/Upload";
import { useEffect, useState } from "react";
import useStyles from "./styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const ExerciseCategoryEdit = (props) => {
  const classes = useStyles();

  const [addFarsi, setAddFarsi] = useState(false);

  useEffect(() => {
    if (props?.record?.translations?.fa) setAddFarsi(true);
  }, [props]);
  return (
    <Edit {...props}>
      <SimpleForm redirect="show">
        <BooleanInput source={"deleted"} />
        <TextInput source="title" validate={[required()]} />
        <NumberInput source={"order"} />
        <SelectInput
          source="type"
          choices={[
            { id: "CLASS", name: "class" },
            { id: "EXERCISE", name: "exercise" },
          ]}
          validate={[required()]}
        />
        <Upload
          source={"image"}
          type={"image"}
          segment={"exerciseInstructionCategory"}
          validate={[required()]}
        />
        <p className={classes.language}>add language</p>
        <div className={classes.boxbox}>
          <p className={classes.titleBoxBox}>en</p>
          <SelectInput
            label="defaultLanguage"
            source={"translations.defaultLanguage"}
            // validate={[required()]}
            // defaultValue="en"
            choices={[
              { id: "en", name: "en" },
              { id: "fa", name: "fa" },
            ]}
          />
          <TextInput
            label="title"
            source={"translations.en.title"}
            // validate={[required()]}
          />
        </div>
        {!addFarsi && (
          <button
            className={classes.btnFarsi}
            onClick={() => setAddFarsi(true)}
          >
            Add Farsi
          </button>
        )}

        {addFarsi && (
          <div className={classes.boxbox2}>
            <div className={classes.boxTitle}>
              <p className={classes.titleBoxBox}>fa</p>
              <HighlightOffIcon
                style={{ fill: "#FF0000", cursor: "pointer" }}
                onClick={() => setAddFarsi(false)}
              />
            </div>{" "}
            <TextInput
              label="عنوان"
              source={"translations.fa.title"}
              // validate={[required()]}
            />
          </div>
        )}
      </SimpleForm>
    </Edit>
  );
};

export default ExerciseCategoryEdit;
