import React, { useState } from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  TextField,
  TextInput,
  Filter,
  ReferenceField,
  Pagination,
  useListContext,
} from "react-admin";
import resource from "../../config/resource";

import CustomPagination from "../../components/CustomPagination";

const FoodUnitList = (props) => {
  return (
    <List
      filters={<FoodUnitFilter />}
      {...props}
      sort={{ field: "updatedAt", order: "DESC" }}
      pagination={<CustomPagination />}
    >
      <Datagrid rowClick="show">
        <TextField source="name" />
        <ReferenceField reference={resource.foodUnit} source="expectedUnit">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="ratio" />
        <DateField source="updatedAt" locales={"fa-IR"} />
        <BooleanField addLabel source="deleted" />
      </Datagrid>
    </List>
  );
};
const FoodUnitFilter = (props) => (
  <Filter {...props}>
    <TextInput
      className="Search"
      label="Search"
      source="name"
      alwaysOn
      filterToQuery={(searchText) => ({ name: searchText })}
    />
  </Filter>
);
export default FoodUnitList;
