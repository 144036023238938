import React from "react";
import {
  ArrayInput,
  BooleanInput,
  Button,
  Edit,
  FormDataConsumer,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useMutation,
  AutocompleteArrayInput,
} from "react-admin";
import { Box, Grid } from "@material-ui/core";
import resource from "../../config/resource";
import Upload from "../../components/inputs/Upload";
import CustomForm from "../../components/form/CustomForm";
import CustomFormIterator from "../../components/form/CustomFormIterator";
import useCustomNotify from "../../components/useCustomNotify";
import { useEffect, useState } from "react";
import useStyles from "./styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const DietTypeEdit = (props) => {
  const classes = useStyles();

  const [addFarsi, setAddFarsi] = useState(false);

  useEffect(() => {
    if (props?.record?.translations?.fa) setAddFarsi(true);
  }, [props]);
  return (
    <Edit {...props} aside={<Aside />}>
      <TabbedForm redirect="show">
        <FormTab label={"details"}>
          <BooleanInput source="completed" label="completed" />
          <Upload segment={"dietType"} source={"image"} type={"image"} />
          <TextInput source="name" validate={[required()]} />
          <ReferenceInput
            source="dietTypeCategory"
            reference={resource.dietTypeCategory}
          >
            <SelectInput source="name" />
          </ReferenceInput>
          <SelectInput
            source="type"
            choices={[
              { id: "hourly", name: "Hourly Fasting" },
              { id: "daily", name: "Daily Fasting" },
              { id: "ramadan", name: "رمضان" },
            ]}
            resettable
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.type && <NumberInput source="fasting" />
            }
          </FormDataConsumer>
          <NumberInput source="days" />
          <TextInput
            source="description"
            fullWidth
            multiline
            validate={[required()]}
          />
          <ReferenceArrayInput
            source="packageCalories"
            reference={resource.packageCalorie}
            label="Calorie Amount Steps"
            validate={[required()]}
          >
            <SelectArrayInput optionText="amount" />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            source="allowedFoods"
            reference={resource.food}
            label="Allowed Foods"
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            source="notAllowedFoods"
            reference={resource.food}
            label="Not Allowed Foods"
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <TextInput multiline source={"sideEffects"} />
          <TextInput multiline source={"benefits"} />
          <TextInput multiline source={"recommendations"} />
          <TextInput multiline source={"target"} />
        </FormTab>
        <FormTab label={"translations"}>
          <div className={classes.boxbox}>
            <p className={classes.titleBoxBox}>en</p>
            {/* <TextInput
              label="defaultLanguage"
              source={"translations.defaultLanguage"}
              validate={[required()]}
              // defaultValue="en"
              disabled
            /> */}
            <SelectInput
              label="defaultLanguage"
              source={"translations.defaultLanguage"}
              // validate={[required()]}
              // // defaultValue="en"
              choices={[
                { id: "en", name: "en" },
                { id: "fa", name: "fa" },
              ]}
            />
            <TextInput
              label="name"
              source={"translations.en.name"}
              // validate={[required()]}
            />
            <TextInput
              label="description"
              source={"translations.en.description"}
              // validate={[required()]}
            />
          </div>
          {!addFarsi && (
            <button
              className={classes.btnFarsi}
              onClick={() => setAddFarsi(true)}
            >
              Add Farsi
            </button>
          )}
          {addFarsi && (
            <div className={classes.boxbox2}>
              <div className={classes.boxTitle}>
                <p className={classes.titleBoxBox}>fa</p>
                <HighlightOffIcon
                  style={{ fill: "#FF0000", cursor: "pointer" }}
                  onClick={() => setAddFarsi(false)}
                />
              </div>

              <TextInput
                label="نام"
                source={"translations.fa.name"}
                // validate={[required()]}
              />
              <TextInput
                label="توضیحات"
                source={"translations.fa.description"}
                // validate={[required()]}
              />
            </div>
          )}
        </FormTab>
        <FormTab label={"meal ratio array"}>
          <ArrayInput
            source="mealRatioArray"
            label="Meal Ratio Arrays"
            validate={[required()]}
          >
            <SimpleFormIterator>
              <TextInput source="type" label={"type"} />
              <ArrayInput
                source="mealRatios"
                label="Meal Ratio Array"
                validate={[required()]}
              >
                <CustomFormIterator>
                  <Grid container spacing={2}>
                    <Grid item>
                      <SelectInput
                        label={"meal"}
                        source="meal"
                        choices={[
                          { id: "breakfast", name: "breakfast" },
                          { id: "lunch", name: "lunch" },
                          { id: "dinner", name: "dinner" },
                          { id: "snack1", name: "snack1" },
                          { id: "snack2", name: "snack2" },
                          { id: "snack3", name: "snack3" },
                          { id: "snack4", name: "snack4" },
                        ]}
                        validate={[required()]}
                      />
                    </Grid>
                    <Grid item>
                      <TextInput source="meal_text" label={"text"} />
                    </Grid>
                    <Grid item>
                      <NumberInput
                        source="ratio"
                        label="ratio(%)"
                        validate={[required()]}
                      />
                    </Grid>
                  </Grid>
                </CustomFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const Aside = ({ record }) => {
  const notify = useCustomNotify();
  const [addType, { loading: addLoading }] = useMutation();
  const [delType, { loading: delLoading }] = useMutation();
  const onAddType = (values) =>
    addType(
      {
        type: "addType",
        payload: { ...values },
      },
      {
        onSuccess: notify,
        onFailure: notify,
      }
    );
  const onDelType = (values) =>
    delType(
      {
        type: "delType",
        payload: { ...values },
      },
      {
        onSuccess: notify,
        onFailure: notify,
      }
    );
  const handleSubmit = (values) => {
    if (values.action === "del") {
      onDelType({
        meal: values.meal,
        dietType: values.dietType,
        id: values.id,
      });
    } else {
      onAddType({
        meal: values.meal,
        dietType: values.dietType,
        id: values.id,
      });
    }
  };
  return (
    <CustomForm
      initialValues={{ id: record?.id }}
      saving={addLoading || delLoading}
      redirect={false}
      save={handleSubmit}
      toolbar={null}
    >
      {(formState, form) => (
        <>
          <SelectInput
            source="meal"
            choices={[
              { id: "breakfast", name: "breakfast" },
              { id: "lunch", name: "lunch" },
              { id: "dinner", name: "dinner" },
              { id: "snack1", name: "snack1" },
              { id: "snack2", name: "snack2" },
              { id: "snack3", name: "snack3" },
              { id: "snack4", name: "snack4" },
            ]}
            validate={[required()]}
          />
          <ReferenceInput
            reference={resource.dietType}
            source={"dietType"}
            validate={required()}
          >
            <SelectInput />
          </ReferenceInput>
          <Box m={1}>
            <Button
              fullWidth
              variant={"contained"}
              onClick={() => {
                form.change("action", "del");
                form.submit();
              }}
              disabled={addLoading || delLoading}
              label={"Delete"}
            />
          </Box>
          <Box m={1}>
            <Button
              fullWidth
              variant={"contained"}
              onClick={() => {
                form.change("action", "add");
                form.submit();
              }}
              disabled={addLoading || delLoading}
              label={"Add"}
            />
          </Box>
        </>
      )}
    </CustomForm>
  );
};

export default DietTypeEdit;
