import {Admin} from "react-admin";
import CustomLayout from "./components/Layout/CustomLayout";
import Login from "./pages/login/login";
import dataProvider from "./config/dataProvider";
import authProvider from "./config/authProvider";
import {UserList} from "./pages/user/UserList";
import UserShow from "./pages/user/UserShow";
import {StylesProvider} from '@material-ui/core/styles';
import {jss, theme} from "./config/theme";
import i18nProvider from "./config/i18nProvider";
import JalaliUtils from "@date-io/jalaali";
import moment from 'moment-jalaali';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import UserEdit from "./pages/user/UserEdit";
import AuthorizedResource from "./components/permission/AuthorizedResource";
import DietTypeList from "./pages/dietType/DietTypeList";
import DietTypeShow from "./pages/dietType/DietTypeShow";
import DietTypeEdit from "./pages/dietType/DietTypeEdit";
import DietTypeCreate from "./pages/dietType/DietTypeCreate";
import DietTypeCategoryList from "./pages/dietTypeCategory/DietTypeCategoryList";
import DietTypeCategoryShow from "./pages/dietTypeCategory/DietTypeCategoryShow";
import DietTypeCategoryCreate from "./pages/dietTypeCategory/DietTypeCategoryCreate";
import DietTypeCategoryEdit from "./pages/dietTypeCategory/DietTypeCategoryEdit";
import SpecialTypeList from "./pages/specialType/SpecialTypeList";
import SpecialTypeShow from "./pages/specialType/SpecialTypeShow";
import SpecialTypeEdit from "./pages/specialType/SpecialTypeEdit";
import SpecialTypeCreate from "./pages/specialType/SpecialTypeCreate";
import RecipeCatsList from "./pages/recipeCats/RecipeCatsList";
import RecipeCatsShow from "./pages/recipeCats/RecipeCatsShow";
import RecipeCatsEdit from "./pages/recipeCats/RecipeCatsEdit";
import RecipeCatsCreate from "./pages/recipeCats/RecipeCatsCreate";
import FoodTagList from "./pages/foodTag/FoodTagList";
import FoodTagShow from "./pages/foodTag/FoodTagShow";
import FoodTagEdit from "./pages/foodTag/FoodTagEdit";
import FoodTagCreate from "./pages/foodTag/FoodTagCreate";
import FoodUnitList from "./pages/foodUnit/FoodUnitList";
import FoodUnitShow from "./pages/foodUnit/FoodUnitShow";
import FoodUnitEdit from "./pages/foodUnit/FoodUnitEdit";
import FoodUnitCreate from "./pages/foodUnit/FoodUnitCreate";
import HatedFoodList from "./pages/hatedFood/HatedFoodList";
import HatedFoodShow from "./pages/hatedFood/HatedFoodShow";
import HatedFoodEdit from "./pages/hatedFood/HatedFoodEdit";
import HatedFoodCreate from "./pages/hatedFood/HatedFoodCreate";
import PackageCalorieList from "./pages/packageCalorie/PackageCalorieList";
import PackageCalorieShow from "./pages/packageCalorie/PackageCalorieShow";
import PackageCalorieEdit from "./pages/packageCalorie/PackageCalorieEdit";
import PackageCalorieCreate from "./pages/packageCalorie/PackageCalorieCreate";
import DiseaseList from "./pages/disease/DiseaseList";
import DiseaseShow from "./pages/disease/DiseaseShow";
import DiseaseEdit from "./pages/disease/DiseaseEdit";
import DiseaseCreate from "./pages/disease/DiseaseCreate";
import resource from "./config/resource";
import PackageList from "./pages/package/PackageList";
import PackageShow from "./pages/package/PackageShow";
import PackageCreate from "./pages/package/PackageCreate";
import PackageEdit from "./pages/package/PackageEdit";
import FoodList from "./pages/food/FoodList";
import FoodEdit from "./pages/food/FoodEdit";
import FoodCreate from "./pages/food/FoodCreate";
import RecipeList from "./pages/recipe/RecipeList";
import RecipeShow from "./pages/recipe/RecipeShow";
import RecipeCreate from "./pages/recipe/RecipeCreate";
import RecipeEdit from "./pages/recipe/RecipeEdit";
import FoodShow from "./pages/food/FoodShow";
import GroceryCategoryList from "./pages/groceryCategory/GroceryCategoryList";
import GroceryCategoryShow from "./pages/groceryCategory/GroceryCategoryShow";
import GroceryCategoryEdit from "./pages/groceryCategory/GroceryCategoryEdit";
import GroceryCategoryCreate from "./pages/groceryCategory/GroceryCategoryCreate";
import UserCreate from "./pages/user/UserCreate";
import MealCategoryList from "./pages/mealCategory/MealCategoryList";
import MealCategoryCreate from "./pages/mealCategory/MealCategoryCreate";
import MealCategoryEdit from "./pages/mealCategory/MealCategoryEdit";
import MealCategoryShow from "./pages/mealCategory/MealCategoryShow";
import CategoryList from "./pages/category/CategoryList";
import CategoryShow from "./pages/category/CategoryShow";
import CategoryEdit from "./pages/category/CategoryEdit";
import CategoryCreate from "./pages/category/CategoryCreate";
import ExerciseTagCategoryList from "./pages/exerciseTagCategory/ExerciseTagCategoryList";
import ExerciseTagCategoryShow from "./pages/exerciseTagCategory/ExerciseTagCategoryShow";
import ExerciseTagCategoryEdit from "./pages/exerciseTagCategory/ExerciseTagCategoryEdit";
import ExerciseTagCategoryCreate from "./pages/exerciseTagCategory/ExerciseTagCategoryCreate";
import ExerciseTagList from "./pages/exerciseTag/ExerciseTagList";
import ExerciseTagShow from "./pages/exerciseTag/ExerciseTagShow";
import ExerciseTagEdit from "./pages/exerciseTag/ExerciseTagEdit";
import ExerciseTagCreate from "./pages/exerciseTag/ExerciseTagCreate";
import ExerciseCategoryList from "./pages/exerciseCategory/ExerciseCategoryList";
import ExerciseCategoryShow from "./pages/exerciseCategory/ExerciseCategoryShow";
import ExerciseCategoryEdit from "./pages/exerciseCategory/ExerciseCategoryEdit";
import ExerciseCategoryCreate from "./pages/exerciseCategory/ExerciseCategoryCreate";
import ExerciseInstructionList from "./pages/exerciseInstruction/ExerciseInstructionList";
import ExerciseInstructionShow from "./pages/exerciseInstruction/ExerciseInstructionShow";
import ExerciseInstructionEdit from "./pages/exerciseInstruction/ExerciseInstructionEdit";
import ExerciseInstructionCreate from "./pages/exerciseInstruction/ExerciseInstructionCreate";
import TipList from "./pages/tip/TipList";
import TipCreate from "./pages/tip/TipCreate";
import TipEdit from "./pages/tip/TipEdit";
import TipShow from "./pages/tip/TipShow";
import FoodAddToFoodList from "./pages/foodAddToFood/foodAddToFoodList"
moment.loadPersian({dialect: "persian-modern", usePersianDigits: true});

function App() {
  return (
      <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
        <StylesProvider jss={jss}>
          <Admin loginPage={Login} theme={theme} authProvider={authProvider} dataProvider={dataProvider}
                 i18nProvider={i18nProvider} layout={CustomLayout}
                 title={"Content Admin Panel"}
                 disableTelemetry>
            <AuthorizedResource name={resource.user} list={UserList} show={UserShow} edit={UserEdit} create={UserCreate}/>
            <AuthorizedResource name={resource.dietType} list={DietTypeList} show={DietTypeShow}
                                edit={DietTypeEdit} create={DietTypeCreate}/>
            <AuthorizedResource name={resource.dietTypeCategory} list={DietTypeCategoryList}
                                show={DietTypeCategoryShow} edit={DietTypeCategoryEdit}
                                create={DietTypeCategoryCreate}/>
            <AuthorizedResource name={resource.specialType} list={SpecialTypeList} show={SpecialTypeShow}
                                edit={SpecialTypeEdit} create={SpecialTypeCreate}/>
            <AuthorizedResource name={resource.recipeCategory} list={RecipeCatsList} show={RecipeCatsShow}
                                edit={RecipeCatsEdit} create={RecipeCatsCreate}/>
            <AuthorizedResource name={resource.foodTag} list={FoodTagList} show={FoodTagShow} edit={FoodTagEdit}
                                create={FoodTagCreate}/>
            <AuthorizedResource name={resource.food} list={FoodList} show={FoodShow} edit={FoodEdit} create={FoodCreate}/>
            <AuthorizedResource name={resource.package} list={PackageList} show={PackageShow} edit={PackageEdit}
                                create={PackageCreate}/>
            <AuthorizedResource name={resource.foodUnit} list={FoodUnitList} show={FoodUnitShow}
                                edit={FoodUnitEdit} create={FoodUnitCreate}/>
            <AuthorizedResource name={resource.hatedFood} list={HatedFoodList} show={HatedFoodShow}
                                edit={HatedFoodEdit} create={HatedFoodCreate}/>
            <AuthorizedResource name={resource.packageCalorie} list={PackageCalorieList}
                                show={PackageCalorieShow} edit={PackageCalorieEdit}
                                create={PackageCalorieCreate}/>
            <AuthorizedResource name={resource.mealCategory} list={MealCategoryList} show={MealCategoryShow}
                                edit={MealCategoryEdit} create={MealCategoryCreate}/>
            <AuthorizedResource name={resource.disease} list={DiseaseList} show={DiseaseShow} edit={DiseaseEdit}
                                create={DiseaseCreate}/>
            <AuthorizedResource name={resource.recipe} list={RecipeList} show={RecipeShow} create={RecipeCreate} edit={RecipeEdit}/>
            <AuthorizedResource name={resource.role}/>
            <AuthorizedResource name={resource.groceryCategory} list={GroceryCategoryList} show={GroceryCategoryShow} edit={GroceryCategoryEdit} create={GroceryCategoryCreate}/>
            <AuthorizedResource name={resource.category} list={CategoryList} show={CategoryShow} edit={CategoryEdit} create={CategoryCreate}/>
            <AuthorizedResource name={resource.exerciseInstructionTagCategory} list={ExerciseTagCategoryList} show={ExerciseTagCategoryShow} edit={ExerciseTagCategoryEdit} create={ExerciseTagCategoryCreate}/>
            <AuthorizedResource name={resource.exerciseInstructionTag} list={ExerciseTagList} show={ExerciseTagShow} edit={ExerciseTagEdit} create={ExerciseTagCreate}/>
            <AuthorizedResource name={resource.exerciseInstructionCategory} list={ExerciseCategoryList} show={ExerciseCategoryShow} edit={ExerciseCategoryEdit} create={ExerciseCategoryCreate}/>
            <AuthorizedResource name={resource.exerciseInstruction} list={ExerciseInstructionList} show={ExerciseInstructionShow} edit={ExerciseInstructionEdit} create={ExerciseInstructionCreate}/>
            <AuthorizedResource name={resource.exercise}/>
            <AuthorizedResource name={resource.tip} list={TipList} show={TipShow} create={TipCreate} edit={TipEdit}/>
            <AuthorizedResource name={resource.copyFoodData} list={FoodAddToFoodList}  create={FoodAddToFoodList}/>
          </Admin>
        </StylesProvider>
      </MuiPickersUtilsProvider>
  );
}

export default App;
