import React from "react";
import {
  BooleanInput,
  CheckboxGroupInput,
  Create,
  NumberInput,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import resource from "../../config/resource";
import Upload from "../../components/inputs/Upload";
import { useEffect, useState } from "react";
import useStyles from "./styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const RecipeCatsCreate = (props) => {
  const classes = useStyles();

  const [addFarsi, setAddFarsi] = useState(false);

  useEffect(() => {
    if (props?.record?.translations?.fa) setAddFarsi(true);
  }, [props]);
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" />
        <Upload
          segment={"recipeCategory"}
          type={"image"}
          source={"selectImage"}
        />
        <Upload
          segment={"recipeCategory"}
          type={"image"}
          source={"deselectImage"}
        />
        <SelectInput
          source={"ratio"}
          choices={[
            { id: "1:1", name: "1:1" },
            { id: "16:9", name: "16:9" },
          ]}
        />
        <SelectInput
          source={"type"}
          choices={[
            { id: "recent", name: "recent" },
            { id: "popular", name: "Popular" },
            { id: "fast", name: "fast" },
            { id: "all", name: "All" },
            { id: "kitchen", name: "Kitchen" },
          ]}
        />
        <NumberInput source={"horizontalOrder"} />
        <NumberInput source={"verticalOrder"} />
        <ReferenceArrayInput
          source="tags"
          reference={resource.foodTag}
          label="Tags"
        >
          <CheckboxGroupInput source="name" />
        </ReferenceArrayInput>
        <BooleanInput source="deleted" />
        <p className={classes.language}>add language</p>
        <div className={classes.boxbox}>
          <p className={classes.titleBoxBox}>en</p>
          <SelectInput
            label="defaultLanguage"
            source={"translations.defaultLanguage"}
            // validate={[required()]}
            // defaultValue="en"
            choices={[
              { id: "en", name: "en" },
              { id: "fa", name: "fa" },
            ]}
          />
          <TextInput
            label="text"
            source={"translations.en.text"}
            // validate={[required()]}
          />
          
        </div>
        {!addFarsi && (
          <button
            className={classes.btnFarsi}
            onClick={() => setAddFarsi(true)}
          >
            Add Farsi
          </button>
        )}

        {addFarsi && (
          <div className={classes.boxbox2}>
            <div className={classes.boxTitle}>
              <p className={classes.titleBoxBox}>fa</p>
              <HighlightOffIcon
                style={{ fill: "#FF0000", cursor: "pointer" }}
                onClick={() => setAddFarsi(false)}
              />
            </div>
            <TextInput
              label="متن"
              source={"translations.fa.text"}
              // validate={[required()]}
            />
          </div>
        )}
      </SimpleForm>
    </Create>
  );
};

export default RecipeCatsCreate;
