import React from "react";
import {
  Edit,
  SimpleForm,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectArrayInput,
  TextInput,
  required,
  BooleanInput,
  SelectInput,
} from "react-admin";
import resource from "../../config/resource";
import { useEffect, useState } from "react";
import useStyles from "./styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const TipEdit = (props) => {
  const classes = useStyles();
  const [addFarsi, setAddFarsi] = useState(false);
  useEffect(() => {
    if (props?.record?.translations?.fa) setAddFarsi(true);
  }, [props]);
  return (
    <Edit {...props}>
      <SimpleForm>
        <BooleanInput source={"deleted"} />
        <TextInput
          source={"text"}
          label={"متن"}
          validate={[required()]}
          fullWidth
        />

        <SelectInput
          label="adviceType"
          source={"adviceType"}
          choices={[
            { id: "water", name: "water" },
            { id: "food", name: "food" },
            { id: "weight", name: "weight" },
            { id: "activity", name: "activity" },
            { id: "calorie", name: "calorie" },
            { id: "step", name: "step" },
          ]}
        />

        <ReferenceArrayInput
          reference={resource.disease}
          source={"diseases"}
          label={"بیماری ها"}
        >
          <AutocompleteArrayInput optionText={"name"} />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          reference={resource.specialType}
          source={"specialTypes"}
          label={"نوع رژیم"}
        >
          <AutocompleteArrayInput optionText={"name"} />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          reference={resource.dietType}
          source={"dietTypes"}
          label={"نوع برنامه غذایی"}
        >
          <AutocompleteArrayInput optionText={"name"} />
        </ReferenceArrayInput>
        <SelectArrayInput
          label={"هدف"}
          source={"weightGoal"}
          choices={[
            { id: "weightGain", name: "weightGain" },
            { id: "weightLoss", name: "weightLoss" },
            { id: "weightFix", name: "weightFix" },
            { id: "pregnancy", name: "pregnancy" },
            { id: "breastfeeding", name: "breastfeeding" },
          ]}
        />
        <SelectArrayInput
          label={"روش برنامه"}
          source={"goalMethod"}
          choices={[
            { id: "diet", name: "diet" },
            { id: "calorieMeter", name: "calorieMeter" },
          ]}
        />

        <p className={classes.language}>add language</p>
        <div className={classes.boxbox}>
          <p className={classes.titleBoxBox}>en</p>
          <SelectInput
            label="defaultLanguage"
            source={"translations.defaultLanguage"}
            // validate={[required()]}
            // defaultValue="en"
            choices={[
              { id: "en", name: "en" },
              { id: "fa", name: "fa" },
            ]}
          />
          <TextInput
            label="text"
            source={"translations.en.text"}
            // validate={[required()]}
          />
        </div>
        {!addFarsi && (
          <button
            className={classes.btnFarsi}
            onClick={() => setAddFarsi(true)}
          >
            Add Farsi
          </button>
        )}

        {addFarsi && (
          <div className={classes.boxbox2}>
            <div className={classes.boxTitle}>
              <p className={classes.titleBoxBox}>fa</p>
              <HighlightOffIcon
                style={{ fill: "#FF0000", cursor: "pointer" }}
                onClick={() => setAddFarsi(false)}
              />
            </div>
            <TextInput
              label="متن"
              source={"translations.fa.text"}
              // validate={[required()]}
            />
          </div>
        )}
      </SimpleForm>
    </Edit>
  );
};

export default TipEdit;
