import React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import resource from "../../config/resource";
import Upload from "../../components/inputs/Upload";
import TagsInput from "./TagsInput";
import { useEffect, useState } from "react";
import useStyles from "./styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const ExerciseInstructionCreate = (props) => {
  const validate = (values) => {
    const errors = {};
    if (values.isPremium && !values.subscriptionType)
      errors.subscriptionType = "اجباری";
    return errors;
  };

  const classes = useStyles();

  const [addFarsi, setAddFarsi] = useState(false);

  useEffect(() => {
    if (props?.record?.translations?.fa) setAddFarsi(true);
  }, [props]);

  return (
    <Create {...props}>
      <SimpleForm redirect="show" validate={validate}>
        <BooleanInput source={"isPremium"} />
        <TextInput source="title" validate={[required()]} />
        <TextInput
          source="description"
          fullWidth
          multiline
          validate={[required()]}
        />
        <ReferenceInput
          filterToQuery={(text) => ({ name: text })}
          source={"exercise"}
          reference={resource.exercise}
          validate={[required()]}
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput
          source={"category"}
          reference={resource.exerciseInstructionCategory}
          validate={[required()]}
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) =>
            formData.isPremium && (
              <SelectInput
                source="subscriptionType"
                choices={[
                  { id: "pro", name: "pro" },
                  { id: "regular", name: "regular" },
                ]}
              />
            )
          }
        </FormDataConsumer>
        <TagsInput />
        <Upload
          source={"image"}
          type={"image"}
          segment={"exerciseInstructionImage"}
          validate={[required()]}
        />
        <Upload
          source={"video"}
          type={"video"}
          segment={"exerciseInstructionVideo"}
          validate={[required()]}
        />
        <NumberInput source={"time"} validate={[required()]} />
        <p className={classes.language}>add language</p>
        <div className={classes.boxbox}>
          <p className={classes.titleBoxBox}>en</p>
          <SelectInput
            label="defaultLanguage"
            source={"translations.defaultLanguage"}
            // validate={[required()]}
            // defaultValue="en"
            choices={[
              { id: "en", name: "en" },
              { id: "fa", name: "fa" },
            ]}
          />
          <TextInput
            label="title"
            source={"translations.en.title"}
            // validate={[required()]}
          />
        </div>
        {!addFarsi && (
          <button
            className={classes.btnFarsi}
            onClick={() => setAddFarsi(true)}
          >
            Add Farsi
          </button>
        )}

        {addFarsi && (
          <div className={classes.boxbox2}>
            <div className={classes.boxTitle}>
              <p className={classes.titleBoxBox}>fa</p>
              <HighlightOffIcon
                style={{ fill: "#FF0000", cursor: "pointer" }}
                onClick={() => setAddFarsi(false)}
              />
            </div>
            <TextInput
              label="عنوان"
              source={"translations.fa.title"}
              // validate={[required()]}
            />
          </div>
        )}
      </SimpleForm>
    </Create>
  );
};

export default ExerciseInstructionCreate;
