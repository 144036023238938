import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  card: {
    display: "flex",
  },

  boxbox: {
    width: "40%",
    padding: "1rem 1rem",
    borderBottom: "1px solid #000",
    display: "grid",
  },
  boxTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  boxbox2: {
    width: "40%",
    padding: "1rem 1rem",
    display: "grid",
  },
  titleBoxBox: {
    fontSize: "18px",
    fontFamily: "Arial",
  },

  btnFarsi: {
    backgroundColor: "#3f51b5",
    color: "#FFF",
    marginTop: "1rem",
    height: "3rem",
    width: "11rem",
    borderRadius: "0.5rem",
    cursor: "pointer",
    fontFamily: "Arial",
  },
}));
export default useStyles;
