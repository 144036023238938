import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  CheckboxGroupInput,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  SelectArrayInput,
} from "react-admin";
import resource from "../../config/resource";
import useSearchResource from "../../components/resource/useSearchResource";
import set from "lodash/set";
import { getQueryParam } from "../../utils/utils";
import Upload from "../../components/inputs/Upload";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

// todo recipe-admin only has access to icon
const FoodForm = (props) => {
  const classes = useStyles();
  const getUnits = useSearchResource(resource.foodUnit);
  let ratios = props.record.foodUnitRatioArray || [];
  ratios = ratios.map((ratio) => ratio.unitId);
  if (ratios.length > 0)
    props.record.ratios = [...ratios, getUnits({ name: "gram" })[0]?.id];
  const redirect = getQueryParam("redirect") || "list";
  const [addFarsi, setAddFarsi] = useState(false);

  useEffect(() => {
    if (props?.record?.translations?.fa) setAddFarsi(true);
  }, [props]);
  return (
    <TabbedForm
      {...props}
      redirect={redirect}
      validate={(values) => validateFood(values, getUnits)}
    >
      <FormTab label={"details"}>
        <BooleanInput source="deleted" label="deleted" />
        <BooleanInput source="verified" label="verified" />
        <TextInput source="name" validate={[required()]} fullWidth />
        <SelectInput
          source="usdaDataType"
          choices={[
            { id: "Survey (FNDDS)", name: "Survey (FNDDS)" },
            { id: "branded_food", name: "branded_food" },
            { id: "foundation_food", name: "foundation_food" },
            { id: "sr_legacy_food", name: "sr_legacy_food" },
          ]}
        />
        <TextInput source="fdcId" />
        <TextInput source="usdaName" fullWidth />
        <ReferenceInput
          source="primaryFoodUnit._id"
          reference={resource.foodUnit}
          filter={{ id: props.record.ratios }}
          label="Primary Food Unit"
          validate={[required()]}
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          {/* <SelectInput optionText={"name"} /> */}
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            source="name"
            resettable
            suggestionLimit={10}
            query={"name"}
            filterToQuery={(searchText) => ({ name: searchText })}
          />
        </ReferenceInput>
        <ArrayInput source="similarNames">
          <SimpleFormIterator>
            <TextInput source={"name"} validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="barCodes">
          <SimpleFormIterator>
            <TextInput source={"name"} validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceArrayInput
          defaultValue={[]}
          reference={resource.category}
          source={"categories"}
        >
          <SelectArrayInput optionText={"name"} />
        </ReferenceArrayInput>
        <NumberInput source={"score"} defaultValue={0} />
        <Upload segment={"food"} type={"image"} source={"icon"} />
      </FormTab>
      <FormTab label={"translations"}>
        <div className={classes.boxbox}>
          <p className={classes.titleBoxBox}>en</p>
          <SelectInput
            label="defaultLanguage"
            source={"translations.defaultLanguage"}
            // validate={[required()]}
            // defaultValue="en"
            choices={[
              { id: "en", name: "en" },
              { id: "fa", name: "fa" },
            ]}
          />
          <TextInput
            label="name"
            source={"translations.en.name"}
            // validate={[required()]}
          />
        </div>
        {!addFarsi && (
          <button
            className={classes.btnFarsi}
            onClick={() => setAddFarsi(true)}
          >
            Add Farsi
          </button>
        )}

        {addFarsi && (
          <div className={classes.boxbox2}>
           <div className={classes.boxTitle}>
              <p className={classes.titleBoxBox}>fa</p>
              <HighlightOffIcon
                style={{ fill: "#FF0000", cursor: "pointer" }}
                onClick={() => setAddFarsi(false)}
              />
            </div>
            <TextInput
              label="نام"
              source={"translations.fa.name"}
              // validate={[required()]}
            />
          </div>
        )}
      </FormTab>
      <FormTab label={"unit ratio array"}>
        <ArrayInput source="foodUnitRatioArray" label="">
          <SimpleFormIterator>
            <ReferenceInput
              source="unitId"
              reference={resource.foodUnit}
              label="food unit"
              validate={[required()]}
              filterToQuery={(searchText) => ({ name: searchText })}
            >
              <AutocompleteInput
                optionText="name"
                optionValue="id"
                source="name"
                resettable
                suggestionLimit={10}
                query={"name"}
              />
            </ReferenceInput>
            <NumberInput
              source="ratio"
              label="ratio(gr)"
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label={"cooked food array"}>
        <ArrayInput source="cookedFoods" label="">
          <SimpleFormIterator>
            <ReferenceInput
              source="food"
              reference={resource.food}
              label="Cooked Food Name"
              validate={[required()]}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <NumberInput
              source="ratio"
              label="ratio(gr)"
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label={"tags"}>
        <ReferenceArrayInput
          source="tags"
          reference={resource.foodTag}
          label="Tags"
          fullWidth
        >
          <CheckboxGroupInput source="name" />
        </ReferenceArrayInput>

        <ReferenceArrayInput
          source="suitableDiseases"
          reference={resource.disease}
          filter={{ type: "KARAFS", deleted: false }}
          label="suitable fort diseases"
          fullWidth
        >
          <CheckboxGroupInput source="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source="notSuitableDiseases"
          reference={resource.disease}
          filter={{ type: "KARAFS", deleted: false }}
          label="not suitable for diseases"
          fullWidth
        >
          <CheckboxGroupInput source="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source="hatedFoods"
          reference={resource.hatedFood}
          filter={{ type: "KARAFS", deleted: false }}
          label="Hated Foods"
          fullWidth
        >
          <CheckboxGroupInput source="name" />
        </ReferenceArrayInput>
        <CheckboxGroupInput
          source="seasons"
          label="suitable for seasons"
          defaultValue={["summer", "fall", "winter", "spring"]}
          choices={[
            { id: "summer", name: "تابستان" },
            { id: "fall", name: "پاییز" },
            { id: "winter", name: "زمستان" },
            { id: "spring", name: "بهار" },
          ]}
        />
        <BooleanInput
          source="notSuitablePregnancy"
          label="not suitable for pregnancy"
          defaultValue={false}
        />
        <BooleanInput
          source="notSuitableBreastfeeding"
          label="not suitable for breastfeeding"
          defaultValue={false}
        />
      </FormTab>
      <FormTab label={"Food Fact( /100gr )"}>
        <NumberInput
          source="foodFact.calorie"
          label="Calorie Amount"
          validate={[required()]}
        />
        <NumberInput source="foodFact.protein" label="Protein Amount" />
        <NumberInput source="foodFact.fat" label="Fat" />
        <NumberInput
          source="foodFact.carbohydrate"
          label="Carbohydrate Amount"
        />
        <NumberInput source="foodFact.sugar" label="Sugar Amount" />
        <NumberInput source="foodFact.fiber" label="Fiber Amount" />
        <NumberInput source="foodFact.sodium" label="Sodium Amount" />
        <NumberInput source="foodFact.potassium" label="Potassium Amount" />
        <NumberInput source="foodFact.calcium" label="Calcium Amount" />
        <NumberInput source="foodFact.magnesium" label="Magnesium Amount" />
        <NumberInput source="foodFact.iron" label="Iron Amount" />
        <NumberInput source="foodFact.cholesterol" label="Cholesterol Amount" />
        <NumberInput source="foodFact.phosphor" label="Phosphor Amount" />
        <NumberInput
          source="foodFact.saturatedFat"
          label="Saturated FatAmount"
        />
        <NumberInput
          source="foodFact.polyunsaturatedFat"
          label="Polyunsaturated Fat Amount"
        />

        <NumberInput source="foodFact.transFat" label="Trans Fat Amount" />
        <NumberInput
          source="foodFact.monounsaturatedFat"
          label="monounsaturated Fat Amount"
        />

        <NumberInput source="foodFact.zinc" label="zinc Amount" />

        <NumberInput source="foodFact.copper" label="Copper Amount" />
        <NumberInput source="foodFact.manganese" label="manganese Amount" />
        <NumberInput source="foodFact.vitamin A" label="vitamin A  Amount" />
        <NumberInput source="foodFact.vitamin D" label="vitamin D Amount" />
        <NumberInput source="foodFact.vitamin E" label="vitamin E Amount" />
        <NumberInput source="foodFact.vitamin C" label="vitamin C Amount" />
        <NumberInput source="foodFact.vitamin B-6" label="vitamin B-6 Amount" />
        <NumberInput
          source="foodFact.vitamin B-12"
          label="vitamin B-12 Amount"
        />
        <NumberInput
          source="foodFact.vitamin K (Menaquinone-4)"
          label="vitamin K (Menaquinone-4) Amount"
        />
        <NumberInput
          source="foodFact.vitamin K (phylloquinone)"
          label="vitamin K (phylloquinone) Amount"
        />
        <NumberInput source="foodFact.vitamin B1" label="vitamin B1 Amount" />
        <NumberInput source="foodFact.vitamin B2" label="vitamin B2 Amount" />
        <NumberInput source="foodFact.vitamin B3" label="vitamin B3 Amount" />
        <NumberInput source="foodFact.vitamin B5" label="vitamin B5 Amount" />
        <NumberInput source="foodFact.vitamin B9" label="vitamin B9 Amount" />
      </FormTab>
    </TabbedForm>
  );
};

const validateFood = (values, getUnits) => {
  const gramUnitId = getUnits({ name: "گرم" })[0]?.id;
  const gramUnitIdEN = getUnits({ name: "gram" })[0]?.id;
  var unitError = false;
  const errors = {};

  if (
    values.primaryFoodUnit !== gramUnitId ||
    values?.primaryFoodUnit?._id !== gramUnitIdEN
  ) {
    if (values.primaryFoodUnit) {
      if (
        values.primaryFoodUnit._id !== gramUnitId &&
        values.primaryFoodUnit._id !== gramUnitIdEN
      ) {
        unitError = true;
      }
    } else {
      unitError = true;
    }

    for (var j in values.foodUnitRatioArray) {
      if (values.foodUnitRatioArray[j]?.unitId) {
        if (values.primaryFoodUnit === values.foodUnitRatioArray[j].unitId) {
          unitError = false;
        }
        if (values.primaryFoodUnit) {
          if (
            values.primaryFoodUnit._id === values.foodUnitRatioArray[j].unitId
          ) {
            unitError = false;
          }
        }
      }
    }
  }
  if (unitError) {
    set(
      errors,
      "primaryFoodUnit._id",
      "Unit ratio array must include primary food unit ratio!"
    );
  }
  return errors;
};
export default FoodForm;
