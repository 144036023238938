import React from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  SimpleForm,
  useNotify,
  useRefresh,
} from "react-admin";
import resource from "../../config/resource";
import axio from "../../config/axios";
const FoodAddToFoodList = () => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onSave = (data) => {
    const params = {
      sourceId: data.sourceId,
      destinationId: data.destinationId,
    };
    axio
      .get("/food/copyFoodData", {
        params: params,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(() => {
        notify(`انجام شد `, { type: "success" });
        refresh();
      })
      .catch((err) => {
        notify(err.response.data.message, { type: "warning" });
      });
  };

  return (
    <SimpleForm save={onSave}>
      <ReferenceInput
        source="sourceId"
        reference={resource.food}
        label="غذا"
        validate={[required()]}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <ReferenceInput
        source="destinationId"
        reference={resource.food}
        label="غذا مقصد"
        validate={[required()]}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
    </SimpleForm>
  );
};

export default FoodAddToFoodList;
