import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  SelectInput
} from "react-admin";
import resource from "../../config/resource";
import { useState } from "react";
import useStyles from "./styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const FoodUnitCreate = (props) => {
  const classes = useStyles();
  const [addFarsi, setAddFarsi] = useState(false);
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" />
        <ReferenceInput
          source="expectedUnit"
          reference={resource.foodUnit}
          label="Expected Food Unit"
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            source="name"
            resettable
            suggestionLimit={10}
            query={"name"}
            filterToQuery={(searchText) => ({ name: searchText })}
          />
        </ReferenceInput>
        <TextInput source="ratio" />
        <p className={classes.language}>add language</p>
        <div className={classes.boxbox}>
          <p className={classes.titleBoxBox}>en</p>
          <SelectInput
            label="defaultLanguage"
            source={"translations.defaultLanguage"}
            // validate={[required()]}
            // defaultValue="en"
            choices={[
              { id: "en", name: "en" },
              { id: "fa", name: "fa" },
            ]}
          />
          <TextInput
            label="name"
            source={"translations.en.name"}
            // validate={[required()]}
          />
        </div>
        {!addFarsi && (
          <button
            className={classes.btnFarsi}
            onClick={() => setAddFarsi(true)}
          >
            Add Farsi
          </button>
        )}

        {addFarsi && (
          <div className={classes.boxbox2}>
            <div className={classes.boxTitle}>
              <p className={classes.titleBoxBox}>fa</p>
              <HighlightOffIcon
                style={{ fill: "#FF0000", cursor: "pointer" }}
                onClick={() => setAddFarsi(false)}
              />
            </div>
            <TextInput
              label="نام"
              source={"translations.fa.name"}
              // validate={[required()]}
            />
          </div>
        )}
      </SimpleForm>
    </Create>
  );
};

export default FoodUnitCreate;
