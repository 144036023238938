import {
    BooleanField,
    ChipField,
    Datagrid,
    Filter,
    List,
    NumberInput,
    ReferenceArrayField,
    SingleFieldList,
    TextField,
} from 'react-admin';
import React from "react";
import resource from "../../config/resource";

export const UserList = (props) => {
    return (
        <List filters={<UserFilter/>}  {...props}>
            <Datagrid rowClick="show" size={"medium"}>
                <TextField source="username" label="نام کاربری" emptyText={"-"}/>
                <ReferenceArrayField source="roles" reference={resource.role} label="نوع کاربر">
                    <SingleFieldList linkType={false}>
                        <ChipField source="name"/>
                    </SingleFieldList>
                </ReferenceArrayField>
                <BooleanField source="deleted" label="حذف شده"/>
            </Datagrid>
        </List>
    )
};

const UserFilter = (props) => (
    <Filter {...props}>
        <NumberInput
            label="نام کاربری"
            source="Username"
            alwaysOn
        />
    </Filter>
);