import { httpClient } from "../config/client";
import Service from "./Service";
import api from "../config/api";
import resource from "../config/resource";

class UserService extends Service {
  login({ username, password }) {
    let base64 = window.btoa(`${username}:${password}`);
    return httpClient({
      URL: "/user/login",
      data: null,
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${base64}`,
        Accept: "application/json",
      },
    });
  }

  refreshToken() {
    return httpClient({
      URL: "/user/refresh",
      method: "POST",
      data: { refreshToken: localStorage.getItem("refreshToken") },
    });
  }

  getCurrentUser() {
    return httpClient({ URL: "/user/user" }).then((res) => res.data);
  }
  deleteMany(params) {
    console.log(
      "🚀 ~ file: user.js:33 ~ UserService ~ deleteMany ~ params:",
      params
    );
    return httpClient({ URL: "/user", method: "DELETE", data: params }).then(
      (res) => res.data
    );
  }

  update(params) {
    const { subscriptionMonths } = params.data;
    if (subscriptionMonths)
      api[resource.subscription]
        .create({
          data: { userId: params.id, months: Math.floor(subscriptionMonths) },
        })
        .then(() => {
          return super.update(params);
        });
    return super.update(params);
  }

  changePassword(params) {
    // console.log(params)
    return httpClient({
      URL: `/user/${params.id}/changePassword`,
      method: "PUT",
      data: params.data,
    });
  }
}

export default UserService;
