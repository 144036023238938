import React from 'react';
import {
    BooleanField,
    ChipField,
    DateField,
    ReferenceArrayField,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    TextField
} from 'react-admin';
import resource from "../../config/resource";
import TextArrayField from "../../components/fields/TextArrrayField";

const TipShow = (props) => {
    return (
       <Show {...props}>
           <SimpleShowLayout>
               <BooleanField source="deleted" />
               <DateField source="createdAt" locales={"fa-IR"}/>
               <TextField source={"adviceType"}/>
               <DateField source="updatedAt" locales={"fa-IR"}/>
               <TextField source="text" label={"متن"}/>
               <ReferenceArrayField reference={resource.disease} source={"diseases"} label={"بیماری ها"}>
                   <SingleFieldList>
                       <ChipField source="name"/>
                   </SingleFieldList>
               </ReferenceArrayField>
               <ReferenceArrayField reference={resource.specialType} source={"specialTypes"} label={"نوع رژیم"}>
                   <SingleFieldList>
                       <ChipField source="name"/>
                   </SingleFieldList>
               </ReferenceArrayField>
               <ReferenceArrayField reference={resource.dietType} source={"dietTypes"} label={"نوع برنامه غذایی"}>
                   <SingleFieldList>
                       <ChipField source="name"/>
                   </SingleFieldList>
               </ReferenceArrayField>
              <TextArrayField source={"weightGoal"} label={"هدف"}/>
              <TextArrayField source={"goalMethod"} label={"روش برنامه"}/>
           </SimpleShowLayout>
       </Show>
    );
};

export default TipShow;