import React from "react";
import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { useEffect, useState } from "react";
import useStyles from "./styles";
function DietTypeCategoryEdit(props) {
  const classes = useStyles();

  const [addFarsi, setAddFarsi] = useState(false);

  useEffect(() => {
    if (props?.record?.translations?.fa) setAddFarsi(true);
  }, [props]);
  return (
    <Edit {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" />
        <BooleanInput source="completed" />
        <BooleanInput source="deleted" />
        <p className={classes.language}>add language</p>
        <div className={classes.boxbox}>
          <p className={classes.titleBoxBox}>en</p>
          <TextInput
            label="defaultLanguage"
            source={"translations.defaultLanguage"}
            // validate={[required()]}
            // defaultValue="en"
            disabled
          />
          <TextInput
            label="name"
            source={"translations.en.name"}
            // validate={[required()]}
          />

          <TextInput
            label="description"
            source={"translations.en.description"}
            // validate={[required()]}
          />
        </div>
        {!addFarsi && (
          <button
            className={classes.btnFarsi}
            onClick={() => setAddFarsi(true)}
          >
            Add Farsi
          </button>
        )}

        {addFarsi && (
          <div className={classes.boxbox2}>
            <p className={classes.titleBoxBox}>fa</p>
            <TextInput
              label="نام"
              source={"translations.fa.name"}
              // validate={[required()]}
            />
            <TextInput
              label="توضیحات"
              source={"translations.fa.description"}
              // validate={[required()]}
            />
          </div>
        )}
      </SimpleForm>
    </Edit>
  );
}

export default DietTypeCategoryEdit;
