import React from "react";
import {
  BooleanField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import resource from "../../config/resource";

const FoodUnitShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <BooleanField addLabel source="deleted" />
        <TextField source="name" />
        <ReferenceField reference={resource.foodUnit} source="expectedUnit">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="ratio" />
      </SimpleShowLayout>
    </Show>
  );
};

export default FoodUnitShow;
